<template>
  <div class="chart-container relative">
    <div class="chart-display">
      <line-chart
        :chart-data="chartData"
        :beginAtZero="beginAtZero"
        :custom-tooltip="true"
        @show-tooltip="processTooltipModel"
        :extraOptions="extraOptions"
        v-if="chartType == 'line'"
      ></line-chart>
      <bar-chart
        :chart-data="chartData"
        :custom-tooltip="true"
        :beginAtZero="beginAtZero"
        @show-tooltip="processTooltipModel"
        :extraOptions="extraOptions"
        v-if="chartType == 'bar'"
      ></bar-chart>
    </div>
    <div
      class="chart-tooltip flex items-center space-x-4 bg-tooltip p-2 rounded-xl absolute text-center"
      :class="tooltipClass"
      :style="tooltipStyle" v-if="shouldShowTooltip">
      <div class="text-tooltip-title font-weight-bold pl-2">
        {{ activeTooltip.dataPoints[0].label }}
      </div>
      <div class="bg-accent text-primary text-xs text-center p-2 px-4 rounded-lg">
        {{ activeTooltip.dataPoints[0].value | formatNumber }}
      </div>
    </div>
  </div>
</template>

<script>
  import LineChart from './LineChart';
  import BarChart from './BarChart';
  export default {
    components: {
      LineChart,
      BarChart,
    },
    computed: {
      shouldShowTooltip() {
        return (
          this.activeTooltip &&
          this.activeTooltip.dataPoints &&
          this.activeTooltip.dataPoints.length > 0
        );
      },
      tooltipClass() {
        if (!this.activeTooltip) return '';
        return `x-align-${this.activeTooltip.xAlign} y-align-${this.activeTooltip.yAlign}`;
      },
      tooltipStyle() {
        if (!this.shouldShowTooltip) return {};
        return {
          opacity: 1,
          position: 'absolute',
          left: this.activeTooltip.caretX + 'px',
          top: (this.activeTooltip.dataPoints[0].y + 8) + 'px',
        };
      },
    },
    props: {
      chartType: String,
      chartData: Object,
      beginAtZero: {
        type: Boolean,
        default: true,
      },
      extraOptions: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      return {
        activeTooltip: null,
      };
    },
    filters: {
      formatNumber(n) {
        return parseFloat(n).toLocaleString('en-US')
      }
    },
    methods: {
      processTooltipModel(tooltipModel) {
        this.activeTooltip = tooltipModel;
      },
    },
  };
</script>