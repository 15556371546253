import { initGlobalOptions } from "@/components/Charts/config";
export default {
  props: {
    beginAtZero: Boolean,
    customTooltip: Boolean
  },
  mounted() {
    initGlobalOptions();
  },
  data() {
    return this.localOptions();
  },
  methods: {
    localOptions() {
      if (this.customTooltip) {
        return {
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: this.beginAtZero,
                }
              }]
            },
            tooltips: {
              enabled: false,
              custom: this.processTooltipModel
            }
          }
        }
      }
      return {};
    },
    processTooltipModel(tooltipModel) {
      this.$emit('show-tooltip', tooltipModel);
    }
  }
}
